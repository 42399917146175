<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    userRolesInGroup() {
      return this.$store.getters.userRolesInGroup;
    },
  },
  watch: {
    userRolesInGroup: {
      immediate: true,
      handler(val) {
        if (!val || !val.length) return;

        if (!val.includes("teacher")) {
          this.$router.push({
            name: "group_users",
          });
          return;
        }

        this.$router.push({
          name: "profile",
        });
      },
    },
  },
};
</script>
